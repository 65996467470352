// app/js/controllers/HomeCtrl.js
Service.factory("DEXClients", function DEXClients($q, $http, $rootScope, $timeout, $localStorage, Validate, apiService, FORMATTER){
    var xmlParser = new DOMParser();
    var outputXML = xmlParser.parseFromString('<?xml version="1.0" encoding="utf-8" ?><DEXFileUpload></DEXFileUpload>',"text/xml");
    
    var buildXML = function(outputWrap, wrap, newXML){
        console.time('Building XML');
        var xmlDoc = xmlParser.parseFromString(newXML,"text/xml");
        var xml = xmlDoc.getElementsByTagName(wrap)[0];
        
        if(!outputXML.getElementsByTagName(outputWrap).length){
            var mainElem = outputXML.getElementsByTagName('DEXFileUpload')[0];
            mainElem.innerHTML += '<'+ outputWrap +'></'+ outputWrap +'>';
        }
        
        var wrapElem = outputXML.getElementsByTagName(outputWrap)[0];
        wrapElem.appendChild(xml);
        
        console.timeEnd('Building XML');
        console.info('Output XML', outputXML);
    };
    
    var CSV = function(array) {
        console.time('Building CSV', array);
        // Use first element to choose the keys and the order
        if(!Validate.data.isSet(array[0])){
            console.timeEnd('Failed Building CSV. No data set.');
            return false;
        }
        
        var keys = Object.keys(array[0]);
        // Build header
        var result = keys.join(',') + '\n';
        // Add the rows
        array.forEach(function(obj){
            keys.forEach(function(k, ix){
                if (ix) result += ',';
                
                if(Validate.data.isSet(obj[k]) && 
                   Validate.data.isSet(obj[k][0]) &&
                    k === 'output'){
                    
                    for(var i = 0; i < obj[k].length; i++){
                        if(i !== 0){
                            result += '\n,,,';
                        }
                        result += '"';
                        var subObj = obj[k][i];
                        result += subObj.title +': '+ subObj.status +': '+ subObj.message;
                        result += '"';
                        
                        if(Validate.data.isSet(subObj.extended)){
                            for(var j = 0; j < subObj.extended.length; j++){
                                result += '\n,,,';
                                var extObj = subObj.extended[j];
                                result += ' - '+ extObj.success +': '+ extObj.level +': '+ extObj.message;
                            }
                        }
                    }
                    result += '\n,,,';
                }else if(Validate.data.isSet(obj[k])){
                    result += '"'+ obj[k] +'"';
                }
            });
            result += '\n';
        });
        
        console.timeEnd('Building CSV');
        console.info('Output CSV', result);
        return result;
    };
    
    var clientFunc = function(deferred, type, data){

        // * Check for special cases
        // Is Unidentified Clients
        if(Validate.data.isSet(data.SubType) && data.SubType == 'Unidentified'){
            console.info('Is Unidentified Clients');

            var output = {};
            output.client = data;
            output.specialClient = 'Unidentified Clients';
            output.valid = true;

            return deferred.resolve(output);
        }

        var successMessage = '';
        var dt = '';
        if(data.DateOfBirth !== ''){
            dt = new Date(data.DateOfBirth);
            dt = dt.toISOString();
            dt = dt.substring(0, dt.indexOf('.'));
        }

        var actions = {
            ClientId: data.ClientUID, // Use UID which is prepended in (CiviCRMClients)
            SLK: '', 
            ConsentToProvideDetails: false, 
            ConsentedForFutureContacts: data.ConsentedForFutureContact, 
            GivenName: data.FirstName, 
            FamilyName: data.LastName, 
            IsUsingPsuedonym: data.IsUsingPsuedonym || false, 
            IsBirthDateAnEstimate: data.IsBirthDateAnEstimate, 
            BirthDate: dt, 
            GenderCode: data.Gender, 
            AddressLine1: data.AddressLine1,
            AddressLine2: data.AddressLine2 +" "+ data.AddressLine2_Extra, 
            Suburb: data.Suburb, 
            State: data.State, 
            Postcode: data.Postcode,
            CountryOfBirthCode: data.CountryOfBirthCode, 
            LanguageSpokenAtHomeCode: data.LanguageSpokenAtHomeCode, 
            AboriginalOrTorresStraitIslanderOriginCode: data.AboriginalOrTorresStraitIslanderOriginCode,
            HasDisabilities: data.HasDisabilities,
            IsHomeless: data.IsHomeless, 
            HouseholdCompositionCode: data.HouseholdCompositionCode, 
            MainSourceOfIncomeCode: '', 
            IncomeFrequencyCode: '', 
            IncomeAmount: '', 
            FirstArrivalMonth: '', 
            FirstArrivalYear: '', 
            AncestryCode: '', 
            AccommodationTypeCode: data.AccomodationTypeCode, 
            DVACardStatusCode: data.DVACardStatusCode, 
            EligibleNDIS: data.EligibleNDIS, 
            HasCarer: data.HasCarer, 
            EducationLevel: data.EducationLevel, 
            EmploymentStatus: data.EmploymentStatus, 
            IsCarer: data.IsCarer, 
            Disabilities: data.Disabilities
        };
        
        if(type === 'send'){
            actions.action = 'addClient';
            successMessage = 'Client Successfully Transferred';
        }else{
            actions.action = 'validateClient';
            successMessage = 'Client Successfully Validated';
        }

        console.info("DEX Transaction: Initialising Add Client", actions);
        apiService.post(actions, true, true).then(function(data){
            console.info(successMessage, data);
            
            if(type !== 'send' && Validate.data.isSet(data.xml)){
                buildXML('Clients', 'Client', data.xml);
            }
            
            var output = FORMATTER.process(data);
            output.client = actions;
            deferred.resolve(output);
        }, function(errorData){
            console.error('Issue Processing Client', errorData);
            var output = FORMATTER.process(errorData);
            deferred.reject(output);
        });
    };
    
    var caseFunc = function(that, deferred, type, data){
        var actions = {action: 'getCase', 
                        CaseId: data.CaseUId};
        var successMessage = '';
        var error = '';
        var errorStartingSession = 'Initialising Process Session';

        // Check if case already exists
        console.info("DEX Transaction: Initialising Get Case", actions);
        apiService.post(actions, true, true).then(function(caseData){    
            var output = FORMATTER.process(caseData);
            console.info("Case Data", caseData);
            // Call to session function
            var callSessionFunc = function(that, deferred, type, data){
                if(type === 'send'){
                    that.sendSession(data, output).then(function(newData){
                        deferred.resolve(newData);
                    }, function(errorData){
                        console.error(errorStartingSession, errorData);
                        deferred.reject(errorData);
                    });
                }else{
                    that.validateSession(data, output).then(function(newData){
                        deferred.resolve(newData);
                    }, function(errorData){
                        console.error(errorStartingSession, errorData);
                        deferred.reject(errorData);
                    });
                }
            };

            var hasDifferentUnidentifiedClientCount = caseData.response.success && Validate.data.isSet(data.CaseTotalNumberOfUnidentifiedClients) && (data.CaseTotalNumberOfUnidentifiedClients != caseData.Case.CaseDetail.TotalNumberOfUnidentifiedClients);

            // If case exists create session else create case
            if(caseData.response.success && !hasDifferentUnidentifiedClientCount){
                console.info('Case exists', data.CaseUId);
                // Create Session
                callSessionFunc(that, deferred, type, data, output);
            } else {
                // console.info('Case doesn\'t exist', data.CaseUId);
                if (hasDifferentUnidentifiedClientCount) {
                    console.info('Case exists but requires update', data.CaseUId);
                } else {
                    console.info('Case doesn\'t exist', data.CaseUId);
                }

                var outletActivityId = Validate.data.isSet(caseData.Case) && Validate.data.isSet(caseData.Case.CaseDetail) && Validate.data.isSet(caseData.Case.CaseDetail.OutletActivityId) ? caseData.Case.CaseDetail.OutletActivityId : data.OutletActivityId;
                
                var actions = {
                    CaseId: data.CaseUId,
                    TotalNumberOfUnidentifiedClients: 0, 
                    ClientAttendanceProfileCode: data.ClientAttendanceProfileCode,
                    OutletActivityId: outletActivityId,
                    ServiceSettingCode: data.ServiceSettingCode
                };

                if(Validate.data.isSet(data.CaseEndDate)){
                    actions.EndDate = data.CaseEndDate;
                }
                
                // * Community Event or Clients?
                // If is community event set TotalNumberOfUnidentifiedClients
                // Else set Clients
                if(Validate.data.isSet(data.CaseTotalNumberOfUnidentifiedClients)){
                    actions.TotalNumberOfUnidentifiedClients = data.CaseTotalNumberOfUnidentifiedClients;
                }else{
                    actions.Clients = [
                        {
                            CaseClient: {
                                ClientId: data.ClientUId,
                                ReferralSourceCode: data.ReferralSourceCode
                            }
                        }
                    ];

                    if(Validate.data.isSet(data.PrimaryReasonForAssistance) || Validate.data.isSet(data.SecondaryReasonForAssistance)){
                        actions.Clients[0].CaseClient.ReasonsForAssistance = [];
                    }

                    if(Validate.data.isSet(data.PrimaryReasonForAssistance)){
                        for(var i = 0; i < data.PrimaryReasonForAssistance.length; i++){
                            actions.Clients[0].CaseClient.ReasonsForAssistance.push({
                                ReasonForAssistance: data.PrimaryReasonForAssistance[i]
                            });
                        }
                    }
                    
                    if(Validate.data.isSet(data.SecondaryReasonForAssistance)){
                        for(var i = 0; i < data.SecondaryReasonForAssistance.length; i++){
                            actions.Clients[0].CaseClient.ReasonsForAssistance.push({
                                ReasonForAssistance: data.SecondaryReasonForAssistance[i]
                            });
                        }
                    }
                }
                
                if (type === 'send' && hasDifferentUnidentifiedClientCount) {
                    actions.action = 'updateCase';
                    successMessage = 'Case Successfully Updated';
                }else if(type === 'send'){
                    actions.action = 'addCase';
                    successMessage = 'Case Successfully Transferred';
                }else{
                    actions.action = 'validateCase';
                    successMessage = 'Case Successfully Validated';
                }

                // Create case
                console.info("DEX Transaction: Initialising Add Case", actions);
                apiService.post(actions, true, true).then(function(caseData){
                    console.info(successMessage, caseData);
                    
                    if(type !== 'send' && Validate.data.isSet(caseData.xml)){
                        buildXML('Cases', 'Case', caseData.xml);
                    }
                    
                    output = FORMATTER.process(caseData);

                    // Create Session
                    callSessionFunc(that, deferred, type, data, output);
                }, function(errorData){
                    console.error('Issue Processing Case', errorData);
                    var output = FORMATTER.process(errorData);
                    deferred.reject(output);
                });
            }
        }, function(errorCode){
            console.error('Issue Processing Get Case', errorCode);
            var output = FORMATTER.process(errorCode);
            deferred.reject(output);
        });
    };
    
    // Round Float is duplicated
    var roundFloat = function(amount){
        if(typeof amount === 'undefined'){
            return 0.00;
        }

        var amount = parseFloat(amount);
        return Math.round(amount * 100) / 100;
    };
    
    var sessionFunc = function(deferred, type, data, caseOutput){
        var successMessage = '';

        var dt = '';
        if(Validate.data.isSet(data.haccInternalDate)){
			dt = data.haccInternalDate.replace(' ', 'T');
        }else if(Validate.data.isSet(data.SessionDate)){
			dt = data.SessionDate.replace(' ', 'T');
        }

        var actions = {
            CaseId: data.CaseUId, 
            SessionId:  data.SessionUId, 
            SessionDate: dt, 
            ServiceTypeId: data.ServiceTypeId, 
            TotalNumberOfUnidentifiedClients: 0,
            ClientAttendanceProfileCode: data.ClientAttendanceProfileCode,
            InterpreterPresent: false
        };

        if(Validate.data.isSet(data.ServiceSettingCode)){
            actions.ServiceSettingCode = data.ServiceSettingCode;
        }

        if(Validate.data.isSet(data.TopicCode)){
            actions.TopicCode = data.TopicCode;
        }
        
        // * Community Event or Clients?
        // If is community event set TotalNumberOfUnidentifiedClients
        // Else set Clients
        if(Validate.data.isSet(data.CaseTotalNumberOfUnidentifiedClients)){
            if(Validate.data.isSet(data.SessionTotalNumberOfUnidentifiedClients)){
                actions.TotalNumberOfUnidentifiedClients = data.SessionTotalNumberOfUnidentifiedClients;
            }
        }else{
            actions.Clients = [
                {
                    SessionClient: {
                        ClientId: data.ClientUId,
                        ParticipationCode: 'CLIENT'
                    }
                }
            ];

            if(Validate.data.isSet(data.ReferralTypeCode) || Validate.data.isSet(data.ReferralPurposeCodes)){
                actions.Clients[0].SessionClient.ClientReferralOutWithPurpose = [
                    {
                        Referral: {
                            TypeCode: data.ReferralTypeCode,
                            PurposeCodes: data.ReferralPurposeCodes
                        }
                    }
                ];
            }
        }
        
        if(type === 'send'){
            actions.action = 'addSession';
            successMessage = 'Session Successfully Transferred';
        }else{
            actions.action = 'validateSession';
            successMessage = 'Session Successfully Validated';
        }

        // Check if TotalCost can be passed for session
		var dtTotalCost = 0.00;
        if(Validate.data.isSet(data.TotalCost) && !Validate.number.isZero(data.TotalCost)){
            dtTotalCost += roundFloat(data.TotalCost);
        }
		
		if(Validate.data.isSet(data.FeesChargedLawn) && !Validate.number.isZero(data.FeesChargedLawn)){
			dtTotalCost += roundFloat(data.FeesChargedLawn);
		}
		
		if(!Validate.number.isZero(dtTotalCost)){
			actions.TotalCost = dtTotalCost.toString();
		}

        // Check if SessionTime or MaintenanceHours can be passed for session
        // Convert to minutes (* 60)
		if(Validate.data.isSet(data.FeesCharged) || Validate.data.isSet(data.FeesChargedCC)){
			var dtFeesCharged = 0;
			
			if(Validate.data.isSet(data.FeesCharged)){
				dtFeesCharged += roundFloat(data.FeesCharged);
			}

			if(Validate.data.isSet(data.FeesChargedCC)){
				dtFeesCharged += roundFloat(data.FeesChargedCC);
			}
			
			actions.FeesCharged = dtFeesCharged.toString();
		}
		
		// Session time or Maintenance
		var dtTime = 0;
		if(Validate.data.isSet(data.SessionTime) && data.SessionTime !== 0 && data.SessionTime !== '0'){
			dtTime += parseInt(data.SessionTime);
		}
		
		if(Validate.data.isSet(data.MaintenanceHours) && data.MaintenanceHours !== 0 && data.MaintenanceHours !== '0'){
			dtTime += parseInt(data.MaintenanceHours * 60);
		}
		
		if(dtTime !== 0){
			actions.Time = dtTime;
		}
		
        // Check if ExtraItems can be passed for session
        if(data.ServiceTypeId == 55 && !Validate.data.isSet(data.ExtraItem)){
            actions.ExtraItems = [{ExtraItemCode: 'OTHER'}];
        }else if(data.ServiceTypeId == 55){
            actions.ExtraItems = data.ExtraItem;
        }

        // Check if Quantity can be passed for session
        if(Validate.data.isSet(data.Quantity) && data.Quantity !== 0 && data.Quantity !== '0'){
            actions.Quantity = data.Quantity;
        }
            
        // Call to session function
        var callClientAssessmentFunc = function(type, data, output){
            console.log('Call Client Assessment');
            var deferredClientAssessment = $q.defer();
            
            clientAssessmentFunc(deferredClientAssessment, type, data, output);

            return deferredClientAssessment.promise;
        };
        
        // Call to session function
        var callSessionAssessmentFunc = function(type, data, output){
            console.log('Call Session Assessment');
            var deferredSessionAssessment = $q.defer();
            
            sessionAssessmentFunc(deferredSessionAssessment, type, data, output);

            return deferredSessionAssessment.promise;
        };

        var runningClientAssessment = false;
        var runningSessionAssessment = false;

        var completeCall = function(deferred, output){
            if(!runningClientAssessment && !runningSessionAssessment){
                deferred.resolve(output);
            }
        };

        var rejectCall = function(deferred, output){
            deferred.resolve(output);
        };
        
        console.info("DEX Transaction: Initialising Add Session", actions);
        apiService.post(actions, true, true).then(function(sessionData){
            console.info(successMessage, sessionData);
            
            // Build XML
            if(type !== 'send' && Validate.data.isSet(sessionData.xml)){
                buildXML('Sessions', 'Session', sessionData.xml);
            }
            
            var output = FORMATTER.process(sessionData);
            output.session = actions;
            
            // Add case data if exists
            if(Validate.data.isSet(caseOutput.data)){
                output.caseData = caseOutput.data;
            }
            
            output.validCase = caseOutput.valid;
            
            // Determine whether an assessment needs to be lodged
            if(Validate.data.isSet(data.SessionAssessmentPhaseCode) && Validate.data.isSet(data.SessionScoreTypeCode)){
                runningClientAssessment = true;

                callClientAssessmentFunc(type, data, output).then(function(clientAssessmentOutput){
                    console.log('Client Assessment Output', clientAssessmentOutput);

                    if(Validate.data.isSet(clientAssessmentOutput.data)){
                        output.clientAssessmentData = clientAssessmentOutput.data;
                    }

                    runningClientAssessment = false;
                    completeCall(deferred, output);
                }, function(errorData){
                    console.error('Issue Running Client Assessment', errorData);
                    rejectCall(deferred, errorData);
                });
            }

            if(Validate.data.isSet(data.CaseAssessmentPhaseCode) && Validate.data.isSet(data.CaseScoreTypeCode)){
                runningSessionAssessment = true;

                callSessionAssessmentFunc(type, data, output).then(function(sessionAssessmentOutput){
                    console.log('Session Assessment Output', sessionAssessmentOutput);
                    
                    if(Validate.data.isSet(sessionAssessmentOutput.data)){
                        output.sessionAssessmentData = sessionAssessmentOutput.data;
                    }

                    runningSessionAssessment = false;
                    completeCall(deferred, output);
                }, function(errorData){
                    console.error('Issue Running Session Assessment', errorData);
                    rejectCall(deferred, errorData);
                });
            }
            
            completeCall(deferred, output);
        }, function(errorCode){
            console.error('Issue Processing Session', errorCode);
            var output = FORMATTER.process(errorCode);
            deferred.reject(output);
        });
    };
    
    var clientAssessmentFunc = function(deferred, type, data, sessionOutput){
        var successMessage = '';

        var actions = {
            CaseId: data.CaseUId, 
            SessionId:  data.SessionUId, 
            ClientId: data.ClientUId, 
            Assessments: []
        };

        if(Validate.data.isSet(data.SessionAssessments)){
            for(var i = 0; i < data.SessionAssessments.length; i++){
                actions.Assessments.push(data.SessionAssessments[i]);
            }
        }
        
        if(type === 'send'){
            actions.action = 'updateClientAssessments';
            successMessage = 'Client Assessment Successfully Transferred';
        }else{
            actions.action = 'validateClientAssessments';
            successMessage = 'Client Assessment Successfully Validated';
        }
        
        console.info("DEX Transaction: Initialising Client Assessment", actions);
        apiService.post(actions, true, true).then(function(data){
            console.info(successMessage, data);
            
            // Build XML
            if(type !== 'send' && Validate.data.isSet(data.xml)){
                buildXML('ClientAssessments', 'ClientAssessment', data.xml);
            }
            
            var output = FORMATTER.process(data);
            output.clientAssessment = actions;
            
            deferred.resolve(output);
        }, function(errorCode){
            console.error('Issue Processing Client Assessment', errorCode);
            var output = FORMATTER.process(errorCode);
            deferred.reject(output);
        });
    };
    
    var sessionAssessmentFunc = function(deferred, type, data, sessionOutput){
        var successMessage = '';

        var actions = {
            CaseId: data.CaseUId, 
            SessionId:  data.SessionUId, 
            Assessments: []
        };
        
        if(Validate.data.isSet(data.CaseAssessments)){
            for(var i = 0; i < data.CaseAssessments.length; i++){
                actions.Assessments.push(data.CaseAssessments[i]);
            }
        }
        
        if(type === 'send'){
            actions.action = 'updateSessionAssessments';
            successMessage = 'Session Assessment Successfully Transferred';
        }else{
            actions.action = 'validateSessionAssessments';
            successMessage = 'Session Assessment Successfully Validated';
        }
        
        console.info("DEX Transaction: Initialising Session Assessment", actions);
        apiService.post(actions, true, true).then(function(data){
            console.info(successMessage, data);
            
            // Build XML
            if(type !== 'send' && Validate.data.isSet(data.xml)){
                buildXML('SessionAssessments', 'SessionAssessment', data.xml);
            }
            
            var output = FORMATTER.process(data);
            output.sessionAssessment = actions;
            
            deferred.resolve(output);
        }, function(errorCode){
            console.error('Issue Processing Session Assessment', errorCode);
            var output = FORMATTER.process(errorCode);
            deferred.reject(output);
        });
    };

    return {
        registerTransfer: function(data){
            var deferred = $q.defer();
            
            data.action = 'registerTransfer';

            apiService.post(data, true, true).then(function(data){
                console.info('Registered transfer', data);
                var output = FORMATTER.process(data);
                deferred.resolve(output);
            }, function(errorCode){
                console.error('Registering transfer', errorCode);
                var output = FORMATTER.process(errorCode);
                deferred.reject(output);
            });

            return deferred.promise;
        },
        
        getXML: function(){
            console.info('Get XML');
            
            var serializer = new XMLSerializer();
            return serializer.serializeToString(outputXML);
        },
        
        downloadXML: function(data){
            console.info('Download XML');
            
            var oxml;
            if(Validate.data.isSet(data)){
                oxml = data;
            }else if(Validate.data.isSet(outputXML)){
                var serializer = new XMLSerializer();
                oxml = serializer.serializeToString(outputXML);
            }else{
                return false;
            }
            
            var pom = document.createElement('a');

            var filename = "DEXBulkUpload.xml";
            pom = document.createElement('a');

            pom.setAttribute('href', 'data:application/octet-stream;base64,' + btoa(oxml));
            pom.setAttribute('download', filename);

            pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
            pom.draggable = true; 
            pom.classList.add('dragout');

            document.body.appendChild(pom);
            pom.click();
            document.body.removeChild(pom);
        },
        
        downloadCSV: function(data){
            console.info('Download CSV', data);
            
            var csv = CSV(data);
            
            var pom = document.createElement('a');

            var filename = "DEXLog.csv";
            pom = document.createElement('a');

            pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURI(csv));
            pom.setAttribute('download', filename);

            pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
            pom.draggable = true; 
            pom.classList.add('dragout');

            document.body.appendChild(pom);
            pom.click();
            document.body.removeChild(pom);
        },
        
        sendClients: function(data){
            var deferred = $q.defer();
            
            for(var i = 0; i < data.length; i++){
                deferred.resolve(this.sendClient(data[i]));
            }

            return deferred.promise;
        },
        
        sendClient: function(data){
            var deferred = $q.defer();
                
            clientFunc(deferred, 'send', data);

            return deferred.promise;
        },
        
        sendCase: function(data){
            var deferred = $q.defer();
            var that = this;
                
            caseFunc(that, deferred, 'send', data);

            return deferred.promise;
        },
        
        sendSession: function(data, caseOutput){
            var deferred = $q.defer();
                
            sessionFunc(deferred, 'send', data, caseOutput);

            return deferred.promise;
        },
        
        validateClients: function(data){
            var deferred = $q.defer();
            
            for(var i = 0; i < data.length; i++){
                deferred.resolve(this.assessClient(data[i]));
            }

            return deferred.promise;
        },
        
        validateClient: function(data){
            var deferred = $q.defer();

            clientFunc(deferred, 'validate', data);

            return deferred.promise;
        },
        
        validateCase: function(data){
            var deferred = $q.defer();
            var that = this;
                
            caseFunc(that, deferred, 'validate', data);

            return deferred.promise;
        },
        
        validateSession: function(data, caseOutput){
            var deferred = $q.defer();
            
            sessionFunc(deferred, 'validate', data, caseOutput);

            return deferred.promise;
        },
        
        deleteClient: function(data){
            var deferred = $q.defer();

            var actions = {action: 'deleteClient', 
                           id: data.ClientUID};

            apiService.post(actions, true, true).then(function(data){
                console.info('Client Successfully Deleted');
                var output = FORMATTER.process(data);
                deferred.resolve(output);
            }, function(errorCode){
                console.error('Issue Deleting Client', errorCode);
                var output = FORMATTER.process(errorCode);
                deferred.reject(output);
            });

            return deferred.promise;
        },
        
        deleteSession: function(data){
            var deferred = $q.defer();

            var actions = {action: 'deleteSession', 
                           SessionId: data.SessionUId, 
                           CaseId: data.CaseUId};

            apiService.post(actions, true, true).then(function(caseData){
                console.info('Case Successfully Deleted');
                var output = FORMATTER.process(data);

                var actions = {action: 'deleteCase', 
                                CaseId: data.CaseUId};

                apiService.post(actions, true, true).then(function(data){
                    console.info('Session Successfully Deleted');
                    var output2 = FORMATTER.process(data);
                    deferred.resolve(output2);
                }, function(errorCode){
                    console.error('Issue Deleting Session', errorCode);
                    var output = FORMATTER.process(errorCode);
                    deferred.reject(output);
                });
            }, function(errorCode){
                console.error('Issue Deleting Case', errorCode);
                var output = FORMATTER.process(errorCode);
                deferred.reject(output);
            });

            return deferred.promise;
        },
        
        clear: function(){
            
        }
    };
});
